import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  users: [],
  roles: [],
  status: "idle",
  roleStatus: "idle",
  error: null,
};
/**
 * Fetch front end users list
 */
export const fetchUsers = createAsyncThunk(
  "users/list",
  async ({ page, pageSize }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}users/list`,
        {
          params: { page: page, limit: pageSize },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      //console.log('data-------------', data)
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Fetch front end user's role
 */
 export const fetchFrontEndUserRoles = createAsyncThunk(
  "users/roles",
  async (thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}users/roles`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data.roles;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Get user by ID
 * @param {*} state
 * @param {*} userId
 * @returns
 */
export const getUserById = createAsyncThunk(
  "users/userId",
  async ({ userId }, thunkAPI) => {
    //console.log("userId --->", userId);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}users/:userId`,
        {
          params: { userId: userId },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data.users;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Apply bulk action on users
 */
export const updateUsersInBulk = createAsyncThunk(
  "users/update-bulk",
  async ({ users, bulkAction, filters }, thunkAPI) => {
    try {
      // console.log("users", users);
      // console.log("action", bulkAction);
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "users/update-bulk",
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            users: users,
            action: bulkAction,
            filters,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Delete User
 */

export const deleteUser = createAsyncThunk(
  "users/delete",
  async ({ userId }, thunkAPI) => {
    try {
      //console.log("userId", userId);
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "users/delete",
        {
          method: "DELETE",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsers.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.status = "completed";
      state.users = action.payload;
    },
    [fetchUsers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchFrontEndUserRoles.pending]: (state, action) => {
      state.roleStatus = "loading";
    },
    [fetchFrontEndUserRoles.fulfilled]: (state, action) => {
      state.roleStatus = "completed";
      state.roles = action.payload;
    },
    [fetchFrontEndUserRoles.rejected]: (state, action) => {
      state.roleStatus = "failed";
      state.error = action.error.message;
    },
  },
});

export default usersSlice.reducer;
/**
 * Get all users
 * @param {*} state
 * @returns
 */
export const getAllUsers = (state) => state.users.users;
