import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import history from '../utils/history'

import Login from "./auth/login";
import ForgotPass from "./auth/forgotpass";
import ResetPassword from "./auth/resetpassword";
// import ChangePassword from "./settings/change-password";
import BuzzArticlesList from "./articles/buzz"
import ExhibitionArticleList from "./articles/exhibitions"
import PotdArticleList from "./articles/potds"
import ViewArticle from './articles'
import Profile from "./accountsettings/profile";
import ChangePassword from "./accountsettings/change-password";
import ArtworksList from "./artworks"
import ArtworkDetails from "./artworks/details"
import SocialActivitiesList from './social-activities'
import Helpdesk from './helpdesk'
import Artworkdesk from './artworkdesk'
import ListEndUsers from './endUser'
import ArtworkEdit from './artworks/edit'
import EmailTemplatesList from './email-templates'
import StaticMesssage from "./email-templates/static-message"
import NotificationTypesList from './notification-types' 
import ReportedMessage from "./helpdesk/reported-messages"
import Genres from "./artworks/genres";
import WikiArtList from "./articles/wikiart";
import DigitalNFTList  from './articles/digitalnft'
import HowtoDoList from "./articles/howtodo";
import MemberMessage from "./helpdesk/member-messages"
import MasterClass from "./masterclass/masterClass"
import MasterProfile from "./masterclass/masterProfile"
// import StreamChannels from "./masterclass/streams"

// Components
import App from "./app";
import Dashboard from "./dashboard";

// Admin users
import ListAdmins from "./users/admin-users";

//Pages
import ViewUser from "./endUser/view";

import ContentManagment from "./content-managment";
import VideoCategories from "./settings/video-categories";
import GroupCategories from "./settings/group-categories";
import MasterclassCategories from "./settings/masterclass-categories";
import RejectReasons from "./settings/rejectReasons"
import SuspensionReasons from "./settings/suspensionReasons"
import MessageResponse from "./settings/messageResponse"
import MessageResponseArt from "./settings/messageResponseArt"

import Tags from "./settings/tags";
// import VendorSubscriptions from "./settings/vendor-subscriptions";
import UpdatePlanSettings from "./settings/update-vendor-subscription";

import { PrivateRoute } from "./common/privateRoutes";
import OtherArtList from "./articles/otherart";
import GroupsList from "./groups";

const MainComponent = () => {
  return (
    <Router basename={"/"} history={history} >
      <ScrollContext>
        <Switch>
          <Route exact path={`/login`} component={Login} />
          <Route exact path={`/auth/login`} component={Login} />
          <Route exact path={`/forgot-pass`} component={ForgotPass} />
          <Route exact path={`/reset-password`} component={ResetPassword} />

          <App>
            <PrivateRoute exact path={`/`} component={Dashboard} />
            <PrivateRoute exact path={`/dashboard`} component={Dashboard} />
            <PrivateRoute exact path={`/admin/list`} component={ListAdmins} />
            <PrivateRoute exact path={`/users/list`} component={ListEndUsers} />
            <PrivateRoute
              exact
              path={`/users/:action/:userId`}
              component={ViewUser}
            />
            <PrivateRoute
              exact
              path={`/content-managment`}
              component={ContentManagment}
            />
            <PrivateRoute
              exact
              path={`/articles/details/:articleType/:articleId`}
              component={ViewArticle}
            />
            <PrivateRoute
              exact
              path={`/articles/buzz/list`}
              component={BuzzArticlesList}
            />
            <PrivateRoute exact path={'/articles/potd/list'} component={PotdArticleList} />
            <PrivateRoute exact path={'/articles/exhibition/list'} component={ExhibitionArticleList} />
            <PrivateRoute exact path={'/articles/wikiart/list'} component={WikiArtList} />
            <PrivateRoute exact path={'/articles/digitalnft/list'} component={DigitalNFTList} />
            <PrivateRoute exact path={'/articles/howtodo/list'} component={HowtoDoList} />
            <PrivateRoute exact path={'/articles/otherart/list'} component={OtherArtList} />
            <PrivateRoute exact path={`/settings/profile`} component={Profile} />
            <PrivateRoute
              exact
              path={`/settings/video-categories`}
              component={VideoCategories}
            />
            <PrivateRoute
              exact
              path={`/settings/group-categories`}
              component={GroupCategories}
            />
            <PrivateRoute
              exact
              path={`/settings/masterclass-categories`}
              component={MasterclassCategories}
            />
            <PrivateRoute exact path={`/settings/tags`} component={Tags} />
            {/* <PrivateRoute
              path={`/settings/vendor-subscriptions`}
              component={VendorSubscriptions}
            /> */}
            <PrivateRoute
              exact
              path={`/settings/update-subscription`}
              component={UpdatePlanSettings}
            />
            <PrivateRoute
              exact
              path={`/settings/rejectReasons`}
              component={RejectReasons}
            />
            <PrivateRoute
              exact
              path={`/settings/suspensionReasons`}
              component={SuspensionReasons}
            />
                        <PrivateRoute
              exact
              path={`/settings/messageResponse`}
              component={MessageResponse}
            />
              <PrivateRoute
              exact
              path={`/settings/messageResponseArt`}
              component={MessageResponseArt}
            />

            <PrivateRoute
              exact
              path={`/accountsettings/profile`}
              component={Profile}
            />
            <PrivateRoute
              exact
              path={`/accountsettings/change-password`}
              component={ChangePassword}
            />
            <PrivateRoute
              exact
              path={'/artworks/list'}
              component={ArtworksList}
            />
            <PrivateRoute
              exact
              path={'/artworks/edit/:artworkId'}
              component={ArtworkEdit}
            />
            <PrivateRoute
              exact
              path={'/artworks/details/:artworkId'}
              component={ArtworkDetails}
            />
            <PrivateRoute
              exact
              path={'/social-activities/:type'}
              component={SocialActivitiesList}
            />

                        <PrivateRoute
              path={'/help-desk'}
              exact
              component={Helpdesk}
            />
                        <PrivateRoute
              path={'/artwork-desk'}
              exact
              component={Artworkdesk}
            />
            <PrivateRoute
              exact
              path={'/email-template'}
              component={EmailTemplatesList}
            />
            <PrivateRoute
              exact
              path={'/email-template/static-messages'}
              component={StaticMesssage}
            />
            <PrivateRoute
              exact
              path={'/notification-types'}
              component={NotificationTypesList}
            />
            <PrivateRoute
              exact
              path={'/help-desk/reported-messages'}
              component={ReportedMessage}
            />
            <PrivateRoute
              exact
              path={'/help-desk/mass-messaging'}
              component={MemberMessage}
            />
            <PrivateRoute
              exact
              path={'/artworks/genres/list'}
              component={Genres}
            />
            <PrivateRoute
              exact
              path={'/groups/list'}
              component={GroupsList}
            />
            <PrivateRoute
              exact
              path={'/master/master-profile'}
              component={MasterProfile}
            />
            <PrivateRoute
              exact
              path={'/master/master-class'}
              component={MasterClass}
            />
            {/* <PrivateRoute
              exact
              path={'/master/streams'}
              component={StreamChannels}
            /> */}
          </App>
        </Switch>
      </ScrollContext>
    </Router>
  );
};

export default MainComponent;
