import APIHandler from './apiHandler'

const getEndUsers = (params) => APIHandler('users/list', 'get', params)

const getEndUser = (params) => APIHandler('users/user', 'get', params)

const blockUser = (params) => APIHandler('users/block', 'post', params)

const deletePage = (params) => APIHandler('users/deletePage', 'delete', params)

const updateUser = (params) => APIHandler('users/user', 'put', params)

const sendBulkMessage = (params) => APIHandler('helpdesk/sendBulkMessage', 'post', params)

const sendBulkMessageartworkdesk = (params) => APIHandler('artworkdesk/sendBulkMessage', 'post', params)

const searchEndUser = (params) => APIHandler('users/search', 'get', params)

export { getEndUsers, getEndUser, blockUser, sendBulkMessage,sendBulkMessageartworkdesk, updateUser, searchEndUser,deletePage }