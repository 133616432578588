import React, { useState } from 'react'
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { MultiSelectComp } from "../../shared";
import { captalizeFirstChar, markFreePlan } from '../../../utils/stringHelper'
import styled from "styled-components";
import { countriesData } from '../../../constants/countries'
import { regionData  } from '../../../constants/regionData'
import { subRegionData  } from '../../../constants/subRegionData'
import { ReactSelect } from '../../shared'

const selectionRange = {
    autoFocus: false,
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
};

const typeOptions = [{ label: 'Suspend', value: true }, { label: 'Active', value: false }]
const VerifiedOptions = [{ label: 'Not Verified', value: false }, { label: 'Verified', value: true }]
const hasArtworks = [{ label: 'Users with Published Artwork', value: true }, { label: 'Users without Published Artwork', value: false }]

const DateRange = styled.div`
    input {
        height : 19px !important;
    }
    .react-daterange-picker {
        width: 100%;
    }
    .react-daterange-picker__wrapper {
        padding: 4.5px .50rem;
        border: 1px solid #ced4da;
        border-radius: .25rem;
    }
    .react-daterange-picker__button svg {
        stroke: #aaa;
    }
`
const UserListRow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin: 0 30px 15px;
    box-sizing: border-box;
    flex-wrap: wrap;
    @media only screen and (min-width: 701px) and (max-width: 991px) {
        justify-content: space-between;
    }

    &:first-child {
        margin-top: 15px;
    }

    .multi-select, .form-control {
        width : 272px;
        @media only screen and (min-width: 701px) and (max-width: 991px) {
            width: 100%;
            margin-right: 0px;
        }
        @media only screen and (max-width: 700px) {
            width : 100%;
        }
    }

    .user-filter {
        &.singleDropdown{
            & div.basic-single {
                width: 260px;
            }
        }
        display: flex;
        width: auto;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 10px;
        @media only screen and (min-width: 701px) and (max-width: 991px) {
            width: 48%;
            margin-right: 0px;
        }
        @media only screen and (max-width: 700px) {
            margin-right: 0px;
            width: 100%;
        }

    }

    .user-filter:last-child {        
        margin-right: 0px;
        @media only screen and (max-width: 700px) {
            margin-top: 10px;
            h6 {display: none;}
        }        
    }
`


const UserFilters = (props) => {
    const { applyFilters, bulkActionHandler, selectedUsers, allUsersSelected } = props
    const [filters, setFilters] = useState({})
    const userRoles = useSelector((state) => state.users.roles)
    const subscriptions = useSelector((state) => state.common.subscriptions)
    const rolesOptions = [];
    const subscriptionsOptions = [{
        label: 'No Subscription',
        value: 'noSubscription',
    }];
    const [region, setRegion] = useState('')
    const [ subRegion, setSubRegion ] = useState('')
    const newSubRegionData = [...subRegionData]
    const newCountriesData = [...countriesData]
    const [ subRegionDataForDropdown, setSubRegionDataForDropdown ] = useState( newSubRegionData )
    const [ countriesDataForDropDown, setCountriesDataForDropDown ] = useState(newCountriesData)


    if (userRoles) {
        for (let role of userRoles) {
            rolesOptions.push({
                label: captalizeFirstChar(role.roleName),
                value: role._id,
            });
        }
        rolesOptions.push({
            label: 'Gallery Owner',
            value: 'haveActiveGallery',
        });
    }
    if (subscriptions) {
        const freePlans = ['Artist-basic','Member-plus']
        const suffix = '(Free)'
        for (let subscription of subscriptions) {
            subscriptionsOptions.push({
                label: markFreePlan(captalizeFirstChar(subscription.role + "-" + subscription.title),freePlans,suffix),
                value: subscription._id,
            });
        }
    }

    const addFilter = (filter) => {
        setFilters({ ...filters, ...filter })
    }

    const handleSubmit = () => {
        let parsedfilters = {}
        const { registrationDate, subscriptionDate } = filters
        filters.city = filters.city?.trim()
        filters.user = filters.user?.trim()
        if (registrationDate && registrationDate.length) {
            let tempObj = {}
            registrationDate.forEach((dt, index) => {
                if (index) {
                    tempObj.end_date = dt
                } else {
                    tempObj.start_date = dt
                }
                parsedfilters.registrationDate = tempObj
            })
        }
        if (subscriptionDate && subscriptionDate.length) {
            let tempObj = {}
            subscriptionDate.forEach((dt, index) => {
                if (index) {
                    tempObj.end_date = dt
                } else {
                    tempObj.start_date = dt
                }
                parsedfilters.subscriptionDate = tempObj
            })
        }
        applyFilters({ ...filters, ...parsedfilters })
    }

    const resetHandler = () => {
        setFilters({})
        applyFilters({})
        setRegion( '' )
        setSubRegion( '' )
        setCountriesDataForDropDown(countriesData)
    }

    const handleRegionChange = ( e ) => {
        setRegion( e.value )
        let filteredSubreagion = [] 
        newSubRegionData.forEach((subregion) => { 
            if ( e.value === subregion.region ) {
                filteredSubreagion.push( subregion )
            }
        })
        let filteredCoutnriesData = [] 
        filteredSubreagion.forEach(( singleRegion ) => {
            newCountriesData.forEach((country) => { 
                if ( singleRegion.value === country.subregion ) {
                    filteredCoutnriesData.push( country )
                }
              })
        })
      
        
          //setSubRegion('')
            if ( e.value === '' ) {
                setSubRegionDataForDropdown( newSubRegionData )
                setCountriesDataForDropDown( newCountriesData )
            } else {
                setSubRegionDataForDropdown( filteredSubreagion )
                const sortedArray = filteredCoutnriesData.sort((a, b) => {
                    let fa = a.value.toLowerCase(),
                        fb = b.value.toLowerCase();
                
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
                setCountriesDataForDropDown( sortedArray )
            }
    }

    const handleSubRegionChange = ( e ) => {
        setSubRegion( e.value )
        let filteredCoutnriesData = [] 
        newCountriesData.forEach((country) => { 
            if ( e.value === country.subregion ) {
                filteredCoutnriesData.push( country )
            }
          })
          //setSubRegion('')
            if ( e.value === '' ) {
            setCountriesDataForDropDown( newCountriesData )
            } else {
                setCountriesDataForDropDown( filteredCoutnriesData )
            }
    }

    return <>
        <UserListRow> {(selectedUsers && selectedUsers.length) || allUsersSelected ? <div className="user-filter">
            <h6>Bulk Actions</h6>
            <select
                name="bulkAction"
                className="form-control"
                onChange={(e) => bulkActionHandler(e.target.value)}
                value=""
            >
                <option value="">Bulk Action</option>
                <option value="disable">Suspend</option>
                <option value="enable">Activate</option>
                {/* <option value="delete">Delete</option> */}
                <option value="sendMessage">Send Message</option>
                <option value="sendNotice">Send Notice</option>
            </select>
        </div> : null}
            <div className="user-filter">
                <h6>Registration Date</h6>
                <DateRange>
                    <DateRangePicker
                        name="registrationDate"
                        ranges={[selectionRange]}
                        value={get(filters, 'registrationDate', null)}
                        maxDate={new Date()}
                        onChange={(date) => addFilter({ registrationDate: date })}
                    />
                </DateRange>
            </div>
            {/* for later use */}
            {/* <div className="user-filter">
                <h6>Subscription Date</h6>
                <DateRange>
                    <DateRangePicker
                        name="subscriptionDate"
                        ranges={[selectionRange]}
                        value={get(filters, 'subscriptionDate', null)}
                        maxDate={new Date()}
                        onChange={(date) => addFilter({ subscriptionDate: date })}
                    />
                </DateRange>
            </div> */}
            <div className="user-filter">
                <h6>User Role</h6>
                <MultiSelectComp
                    options={rolesOptions || []}
                    values={get(filters, 'userRoleId', [])}
                    placeholder="Select User Role"
                    onSubmit={(v) => addFilter({ userRoleId: v.parsedValues })}
                />
            </div>
            
            <div className="user-filter">
                <h6>Referral?</h6>
                <MultiSelectComp
                    options={[{label: 'Referral', value: 'referral'},{label: 'Invite friends', value: 'InviteFriends'}, {label: 'Not Referral', value: 'normal'}] || []}
                    values={get(filters, 'referralUser', [])}
                    onSubmit={(v) => addFilter({ referralUser: v.parsedValues })}
                    placeholder="Select Referral"
                />
            </div>
            <div className="user-filter">
                <h6>Subscription Type</h6>
                <MultiSelectComp
                    options={subscriptionsOptions || []}
                    values={get(filters, 'subscription', [])}
                    placeholder="Select Subscription"
                    onSubmit={(v) => addFilter({ subscription: v.parsedValues })}
                />
            </div>
            <div className="user-filter">
                <h6>User</h6>
                <input
                    className="form-control"
                    placeholder="Search username, email"
                    maxLength={40}
                    value={get(filters, 'user', '')}
                    onChange={(e) => addFilter({ user: e.target.value })}
                    onKeyPress={(e) => { e.key === 'Enter' && applyFilters(filters) }}
                />
            </div>
            <div className="user-filter singleDropdown">
                <h6>Select Region</h6>
                <ReactSelect
                    name="Region"
                    options={regionData}
                    className="selectDropdown"
                    values={[region]}
                    onChange={handleRegionChange}
                    //onChange={}
                    //values={[country]}
                />
            </div>
            <div className="user-filter singleDropdown">
                <h6>Select Subregion</h6>
                <ReactSelect
                    name="Sub Region"
                    options={subRegionDataForDropdown}
                    className="selectDropdown"
                    values={[subRegion]}
                    onChange={handleSubRegionChange}
                    //onChange={}
                    //values={[country]}
                />
            </div>
            <div className="user-filter">
                <h6>Country</h6>
                <MultiSelectComp
                    options={countriesDataForDropDown}
                    values={get(filters, 'country', [])}
                    onSubmit={(v) => addFilter({ country: v.parsedValues })}
                    placeholder="Select Country"
                />
            </div>
            <div className="user-filter">
                <h6>City</h6>
                <input
                    name="city"
                    className="form-control"
                    maxLength={40}
                    placeholder="Search city"
                    value={get(filters, 'city', '')}
                    onChange={(e) => addFilter({ city: e.target.value })}
                    onKeyPress={(e) => { e.key === 'Enter' && applyFilters(filters) }}
                />
            </div>
            <div className="user-filter">
                <h6>User Type</h6>
                <MultiSelectComp
                    options={typeOptions || []}
                    values={get(filters, 'isBlocked', [])}
                    placeholder="Select User Status"
                    onSubmit={(v) => addFilter({ isBlocked: v.parsedValues })}
                />
            </div>
            <div className="user-filter">
                <h6>Verified User</h6>
                <MultiSelectComp
                    options={VerifiedOptions || []}
                    values={get(filters, 'isVerified', [])}
                    placeholder="Select User Status"
                    onSubmit={(v) => addFilter({ isVerified: v.parsedValues })}
                />
            </div>
            <div className="user-filter">
                <h6>Artwork Publication Status</h6>
                <MultiSelectComp
                    options={hasArtworks || []}
                    values={get(filters, 'hasArtworks', [])}
                    placeholder="Select User Status"
                    onSubmit={(v) => addFilter({ hasArtworks: v.parsedValues })}
                />
            </div>
            <div className="user-filter mr-0">
                <h6>&nbsp;</h6>
                <div className="filter-btn">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => handleSubmit(filters)}
                    >
                        Filter
                    </button>
                    &nbsp;
                    <button
                        onClick={() => resetHandler()}
                        type="button"
                        className="btn btn-light"
                    >
                        Reset
                    </button>
                </div>
            </div>
        </UserListRow>



    </>
}

UserFilters.defaultProps = {
    applyFilters: () => null,
    bulkActionHandler: () => null,
    allUsersSelected: false,
    selectedUsers: []
}

UserFilters.propTypes = {
    applyFilters: PropTypes.func.isRequired,
    bulkActionHandler: PropTypes.func.isRequired,
    selectedUsers: PropTypes.array.isRequired,
    allUsersSelected: PropTypes.bool.isRequired,
}


export default UserFilters
