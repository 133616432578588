import React from "react";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import "./index.css"
import 'rc-slider/assets/index.css'

import MainComponent from "./components/main-component";
import SimpleReactLightbox from 'simple-react-lightbox'

import { fetchAdminRoles } from "./features/user/adminSlice";
import { fetchFrontEndUserRoles } from "./features/user/userSlice";
import {
  fetchCountries,
  fetchAllLanguages,
  fetchAllsubscriptions,
} from "./features/common/commonSlice";

import { Provider } from "react-redux";
import store from "./store";

store.dispatch(fetchCountries());
store.dispatch(fetchAdminRoles());
store.dispatch(fetchAllLanguages());
store.dispatch(fetchAllsubscriptions());
store.dispatch(fetchFrontEndUserRoles());

ReactDOM.render(
  <Provider store={store}>
    <SimpleReactLightbox>
      <MainComponent />
    </SimpleReactLightbox>
  </Provider>,
  document.getElementById("root")
);
