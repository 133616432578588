import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from '../../common/breadcrumb'
import { TableComp, ToolTip } from '../../shared'
import get from 'lodash/get'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../../hooks/apiStateHandler'
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../../constants/translations';
import { getReportedMessageList, deleteReportedMessage } from '../../../services/helpdesk'
import { blockUser } from '../../../services/endUserManagement'
import { captalizeFirstChar } from '../../../utils/stringHelper'
import moment from 'moment'

//const editValuesInitial = { showModal: false, values: {}, updating: false, error: null }

const ReportedMessage = () => { 
    const [reportedMessageState, reportedMessageDispatch] = useReducer(ApiStateReducer, initialState)
    const [actionPerforming, setActionPerforming] = useState(null)

    useEffect(() => {
        getReportedMessageHandler({ offset: 0, limit: get(reportedMessageState, 'limit', 20), })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getReportedMessageHandler = async (params) => {
        try {
            reportedMessageDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getReportedMessageList(params)
            if (res.success) {
                reportedMessageDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.reportedMessages', []), offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                reportedMessageDispatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
            reportedMessageDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }
    const onSort = ({ fieldName, sortOrder }) => {
        reportedMessageDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        reportedMessageDispatch({
            offset: 0,
            limit: get(reportedMessageState, 'limit', 20),
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }
    const [ currentPage, setCurrentPage ] = useState(1)
    const onPageChange = (page) => {
        setCurrentPage(page)
        getReportedMessageHandler({
            offset: get(reportedMessageState, 'limit', 20) * (page - 1),
            limit: get(reportedMessageState, 'limit', 20),
            sortField: get(reportedMessageState, 'sorting.sortField'),
            sortOrder: get(reportedMessageState, 'sorting.sortOrder'),
        })
    }
    const suspendUserClickHandler = async ( id ) => {
        const offset = get(reportedMessageState, 'limit', 20) * (currentPage - 1)
        setActionPerforming("suspendingUser")
        const blocked = 'true'
        const res = await blockUser({ userId: id, blocked })
        if (res && res?.success) {
            toast.success(res?.data?.message)
            getReportedMessageHandler({ offset: offset , limit: get(reportedMessageState, 'limit', 20), })
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }

    const deleteMessageClickHandler = async ( reportedMsgId ) => {
        const offset = get(reportedMessageState, 'limit', 20) * (currentPage - 1)
        setActionPerforming("deletingMessage")
        const res = await deleteReportedMessage({ messageId: reportedMsgId})
        if (res && res?.success) {
            toast.success(res?.data?.message)
            getReportedMessageHandler({ offset: offset , limit: get(reportedMessageState, 'limit', 20), })
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }

    const columns = [
        {
            name: "Message Content",
            selector: "messageId",
            width: "35%",
            cellRenderer: (row) => (<div dangerouslySetInnerHTML={{ __html: row.messageId.content }} ></div>),
            //sortable: true
        },
        {
            name: "Reported By",
            selector: "reportedById",
            cellRenderer: (row) => <div>{captalizeFirstChar(get(row, 'reportedById.firstName', 'N/A')) + ' ' + captalizeFirstChar(get(row, 'reportedById.lastName', 'N/A'))}</div>,
            //sortable: true
        },
        {
            name: "Reported User",
            selector: "reportedUserId",
            cellRenderer: (row) => <div>{captalizeFirstChar(get(row, 'reportedUserId.firstName', 'N/A')) + ' ' + captalizeFirstChar(get(row, 'reportedUserId.lastName', 'N/A'))}</div>,
            //sortable: true
        },
        {
            name: "Userrole",
            selector: "reportedUserRole",
            cellRenderer: (row) => <div>{ captalizeFirstChar( get(row, 'reportedUserId.userRoleId.roleName', 'N/A'))}</div>,
            //sortable: true
        },
        {
            name: "Subscription",
            selector: "reportedUserSubscription",
            cellRenderer: (row) => <div>{ captalizeFirstChar( get(row, 'reportedUserId.userSubscriptionId.subscription.title', 'N/A'))}</div>,
            //sortable: true
        },
        {
            name: "is Suspended",
            selector: "isUserSuspended",
            cellRenderer: (row) => (<div dangerouslySetInnerHTML={{ __html: row.reportedUserId.isBlocked ? row.reportedUserId.isBlocked: 'false'}} ></div>),
            //sortable: true
        },
        {
            name: "Date Reported",
            selector: "dateCreated",
            cellRenderer: (row) => ( moment(row.dateUpdated).format("DD MMM, YYYY h:mm:ss a") ),
            //sortable: true
        },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
                {
                    row.reportedUserId.isBlocked === false && ( <><ToolTip tip="Suspend User" id="suspendUser" />
                    <span
                        data-tip
                        data-for="suspendUser"
                        style={{ cursor: "pointer" }}
                        title="Suspend User"
                        onClick={ ()=>suspendUserClickHandler( row.reportedUserId._id )}
                    >
                        <i
                            className="fa fa-user-times"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(40, 167, 69)",
                            }}
                        ></i>
                    </span> </>)
                }
                
                <ToolTip tip="Delete Message" id="deleteMessage" />
                    <span
                        data-tip
                        data-for="deleteMessage"
                        style={{ cursor: "pointer" }}
                        title="Delete Message"
                        onClick={()=>deleteMessageClickHandler( row._id )}
                    >
                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(255, 0, 0)",
                            }}
                        ></i>
                    </span>
                <ToolTip tip="View Profile" id="viewprofile" />
                    <span
                        data-tip
                        data-for="viewprofile"
                        style={{ cursor: "pointer" }}
                        title="View Profile"
                        onClick={() => {
                            //history.push(`/users/${UPDATE}/${row._id}`)
                            const win = window.open(`/users/details/${row.reportedUserId._id}`, "_blank")
                            win.focus()
                        }}
                    >
                        <i
                            className="fa fa-eye"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(0, 191, 255)",
                            }}
                        ></i>
                    </span>
                <ToolTip tip="View User on Frontend" id="viewOnFrontend" />
                    <span
                        data-tip
                        data-for="viewOnFrontend"
                        style={{ cursor: "pointer" }}
                        title="View User on Frontend"
                        onClick={() => {
                            //history.push(`/users/${UPDATE}/${row._id}`)
                            const win = window.open(`https://artmo.com/user/${row.reportedUserId.username}`, "_blank")
                            win.focus()
                        }}
                    >
                        <i
                            className="fa fa-user"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(0, 191, 255)",
                            }}
                        ></i>
                    </span>
            </>)
        }
    ]
    return <>
    <Breadcrumb title='Reported Messages' />
    <div className="container-fluid">
        <div className="card">
            <div className="card-header">
                <h5 className="BackButtonHeading">
                    Reported Messages
                </h5>
            </div>
            <div style={{ margin: "0 30px 15px" }}>
                {!get(reportedMessageState, 'loading') && TOTAL_RECORDS + get(reportedMessageState, 'totalRecords', 0)}
            </div>
            <div className="card-body">
                <TableComp
                    onPageChange={(p) => onPageChange(p)}
                    page={(get(reportedMessageState, 'offset', 0) / get(reportedMessageState, 'limit', 20) + 1)}
                    limit={get(reportedMessageState, 'limit', 20)}
                    totalRecords={get(reportedMessageState, 'totalRecords', 0)}
                    loading={get(reportedMessageState, 'loading')}
                    data={get(reportedMessageState, 'data', [])}
                    columns={columns}
                    onSort={onSort}
                    sortField={get(reportedMessageState, 'sorting.sortField', null)}
                    sortOrder={get(reportedMessageState, 'sorting.sortOrder', null)}
                />
            </div>
        </div>
    </div>
</>
}

export default ReportedMessage