import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from '../../common/breadcrumb'
import { TableComp, ToolTip } from '../../shared'
import get from 'lodash/get'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../../hooks/apiStateHandler'
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../../constants/translations';
import { getMassMemberMessageList, getSingleMemberMessagesList, allowMoreConversations, deleteMemberMessages } from '../../../services/helpdesk'
import { blockUser } from '../../../services/endUserManagement'
import { captalizeFirstChar } from '../../../utils/stringHelper'
import moment from 'moment'
import MessageModal from './message-modal'
import MassMemberSeach from './search'
import styles from '../helpdesk.module.css'

//const editValuesInitial = { showModal: false, values: {}, updating: false, error: null }

const MemberMessage = () => { 
    const [massMessageState, massMessageDispatch] = useReducer(ApiStateReducer, initialState)
    const [actionPerforming, setActionPerforming] = useState(null)
    const [showModal, setShowModal] = useState( false ) ;  
    const [showLoader, setShowLoader] = useState( false ) ; 
    const [ singleMessages, setSingleMessages ] = useState()
    const [appliedfilters, setAppliedFilters] = useState({})
    const [allfilters, setAllFilters] = useState({})

    useEffect(() => {
        getMassMessageHandler({ offset: 0, limit: get(massMessageState, 'limit', 20), })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMassMessageHandler = async (params) => {
        try {
            massMessageDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getMassMemberMessageList(params)
            if (res.success) {
                massMessageDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.MemberMassMessages', []), offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                massMessageDispatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
            massMessageDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }
    const onSort = ({ fieldName, sortOrder }) => {
        massMessageDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        massMessageDispatch({
            offset: 0,
            limit: get(massMessageState, 'limit', 20),
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }
    const [ currentPage, setCurrentPage ] = useState(1)
    const onPageChange = (page) => {
        setCurrentPage(page)
        getMassMessageHandler({
            offset: get(massMessageState, 'limit', 20) * (page - 1),
            limit: get(massMessageState, 'limit', 20),
            sortField: get(massMessageState, 'sorting.sortField'),
            sortOrder: get(massMessageState, 'sorting.sortOrder'),
        })
    }
    const suspendUserClickHandler = async ( id ) => {
        const offset = get(massMessageState, 'limit', 20) * (currentPage - 1)
        setActionPerforming("suspendingUser")
        const blocked = 'true'
        const res = await blockUser({ userId: id, blocked })
        if (res && res?.success) {
            toast.success(res?.data?.message)
            getMassMessageHandler({ offset: offset , limit: get(massMessageState, 'limit', 20), })
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }
    const viewMessagesHandler = async ( id, dateUpdated, messagesCount ) => {
        setActionPerforming("fetcingSingleMessages")
        const nextDate = moment(dateUpdated, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
        setShowLoader(true)
        const res = await getSingleMemberMessagesList ({ id, dateUpdated, nextDate, messagesCount })
        if (res && res?.success) {
            setShowLoader(false)
             setSingleMessages(res?.data?.singleMessagesSent)
             setShowModal( true )
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }
    const deleteMessageRow = async ( row ) => {
        const countId = row?._id
        setActionPerforming("allowingMoreConversations")
        const res = await deleteMemberMessages ({ countId })
        if (res && res?.success) {
            getMassMessageHandler({ offset: 0, limit: get(massMessageState, 'limit', 20), })
            toast.success(res?.data?.message)
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }


    const allowMoreClickHandler = async ( row ) => {
        const countId = row?._id
        console.log( countId ) 
        setActionPerforming("allowingMoreConversations")
        const res = await allowMoreConversations ({ countId })
        if (res && res?.success) {
            getMassMessageHandler({ offset: 0, limit: get(massMessageState, 'limit', 20), })
            toast.success(res?.data?.message)
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }
    const columns = [
        {
            name: "Member Name",
            selector: "membername",
            cellRenderer: (row) => <div>{captalizeFirstChar(get(row, 'userId.firstName', 'N/A')) + ' ' + captalizeFirstChar(get(row, 'userId.lastName', 'N/A'))}</div>,
            //sortable: true
        },
        {
            name: "Username",
            selector: "username",
            cellRenderer: (row) => <div>{captalizeFirstChar(get(row, 'userId.username', 'N/A'))}</div>,
            //sortable: true
        },
        {
            name: "Conversations started",
            selector: "count",
            width: "10%",
            cellRenderer: (row) => <div dangerouslySetInnerHTML={{ __html: row.newConversationCount}}></div>,
            //sortable: true
        },
        {
            name: "Messages",
            cellRenderer: ( row ) => <div style={{
                cursor: "pointer",
                textDecoration: "underline"
            }}class="makeLinK" onClick={ ()=>viewMessagesHandler( row.userId._id, moment(row.dateUpdated).format("YYYY-MM-DD"), row.count ) }> View Sent Messages</div>
        },
        {
            name: "Message Count",
            selector: "count",
            width: "10%",
            cellRenderer: (row) => <div style={{
                cursor: "pointer",
                textDecoration: "underline"
            }}class="makeLinK" onClick={ ()=>viewMessagesHandler( row.userId._id, moment(row.dateUpdated).format("YYYY-MM-DD"), row.count ) } dangerouslySetInnerHTML={{ __html: row.count}}></div>,
            //sortable: true
        },
        {
            name: "is Suspended",
            selector: "isUserSuspended",
            cellRenderer: (row) => (<div dangerouslySetInnerHTML={{ __html: row && row.userId && row.userId.isBlocked ? row && row.userId && row.userId.isBlocked: 'false'}} ></div>),
            //sortable: true
        },
        {
            name: "More Conversation Allowed",
            selector: "moreConversationAllowed",
            cellRenderer: (row) => (<div dangerouslySetInnerHTML={{ __html: row.isMoreConversationsAllowed ? row.isMoreConversationsAllowed: 'false'}} ></div>),
            //sortable: true
        },
        {
            name: "Last Message Time",
            selector: "lastMessageTime",
            cellRenderer: (row) => ( moment(row.dateUpdated).format("DD MMM, YYYY h:mm:ss a") ),
            //sortable: true
        },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
                {
                    row && row.userId && row.userId.isBlocked === false && ( <><ToolTip tip="Suspend User" id="suspendUser" />
                    <span
                        data-tip
                        data-for="suspendUser"
                        style={{ cursor: "pointer" }}
                        title="Suspend User"
                        // onClick={ ()=>suspendUserClickHandler( row.userId._id )}
                        onClick={() => {
                            //history.push(`/users/${UPDATE}/${row._id}`)
                            const win = window.open(`/users/details/${row.userId._id}`, "_blank")
                            win.focus()
                        }}>
                        <i
                            className="fa fa-user-times"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(40, 167, 69)",
                            }}
                        ></i>
                    </span> </>)
                }
                <ToolTip tip="View Profile" id="viewprofile" />
                    <span
                        data-tip
                        data-for="viewprofile"
                        style={{ cursor: "pointer" }}
                        title="View Profile"
                        onClick={() => {
                            //history.push(`/users/${UPDATE}/${row._id}`)
                            const win = window.open(`/users/details/${row.userId._id}`, "_blank")
                            win.focus()
                        }}
                    >
                        <i
                            className="fa fa-eye"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(0, 191, 255)",
                            }}
                        ></i>
                    </span>
                {
                    row && !row.isMoreConversationsAllowed  && (
                        <>
                        <ToolTip tip="Allow More Conversations" id="allowMore" />
                        <span
                            data-tip
                            data-for="allowMore"
                            style={{ cursor: "pointer" }}
                            title="Allow More Conversations"
                            onClick={() => {allowMoreClickHandler(row)}}
                        >
                            <i
                                className="fa fa-check"
                                style={{
                                    width: 35,
                                    fontSize: 18,
                                    padding: 10,
                                    color: "rgb(0, 191, 255)",
                                }}
                            ></i>
                        </span>
                        </>
                    )
                }
                <ToolTip tip="View User on Frontend" id="viewOnFrontend" />
                    <span
                        data-tip
                        data-for="viewOnFrontend"
                        style={{ cursor: "pointer" }}
                        title="View User on Frontend"
                        onClick={() => {
                            //history.push(`/users/${UPDATE}/${row._id}`)
                            const win = window.open(`https://artmo.com/user/${row.userId.username}`, "_blank")
                            win.focus()
                        }}
                    >
                        <i
                            className="fa fa-user"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(0, 191, 255)",
                            }}
                        ></i>
                    </span>
                <ToolTip tip="Delete Row" id="deleteRow" />
                    <span
                        data-tip
                        data-for="deleteRow"
                        style={{ cursor: "pointer" }}
                        title="Delete Row"
                        onClick={() => {deleteMessageRow(row)}}
                    >
                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(0, 191, 255)",
                            }}
                        ></i>
                    </span>
            </>)
        }
    ]

    const applyFilters = (filt) => {
        //history.push('/help-desk')
        setAppliedFilters(filt)
        setAllFilters(filt)
        massMessageDispatch({ type: ApiStateEvents.SUCCESS, data: [], offset: 0, totalRecords: 0 })
        getMassMessageHandler({ limit: get(massMessageState, 'limit', 20), offset: 0, filters: filt })
    }

    return <>
    {
        showLoader && 
        (
            <div className={styles.loaderDiv}>
                <div className={styles.loader}></div>
            </div>
        )
    }
    
    <Breadcrumb title='User Mass Messages' />
    <div className="container-fluid">
        <div className="card">
            <div className="card-header">
                <h5 className="BackButtonHeading">
                    User Mass Messages
                </h5>
            </div>
            <div>
                <MassMemberSeach addFilter={(f) => setAllFilters({ ...allfilters, ...f })} appliedFilters={appliedfilters} allfilters={allfilters} applyFilters={(f) => applyFilters(f)} />
            </div>
            <div style={{ margin: "0 30px 15px" }}>
                {!get(massMessageState, 'loading') && TOTAL_RECORDS + get(massMessageState, 'totalRecords', 0)}
            </div>
            <div className="card-body">
                <TableComp
                    onPageChange={(p) => onPageChange(p)}
                    page={(get(massMessageState, 'offset', 0) / get(massMessageState, 'limit', 20) + 1)}
                    limit={get(massMessageState, 'limit', 20)}
                    totalRecords={get(massMessageState, 'totalRecords', 0)}
                    loading={get(massMessageState, 'loading')}
                    data={get(massMessageState, 'data', [])}
                    columns={columns}
                    onSort={onSort}
                    sortField={get(massMessageState, 'sorting.sortField', null)}
                    sortOrder={get(massMessageState, 'sorting.sortOrder', null)}
                />
            </div>
            {showModal  && <MessageModal
                    //loading={false}
                    onCloseModal={() => setShowModal(false)}
                    mopen="true"
                    values={singleMessages}
                    title="Messages Sent By User"
                />}
        </div>
    </div>
</>
}

export default MemberMessage