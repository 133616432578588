import React, { useState, useEffect, useReducer, Fragment } from 'react'
import { Button } from '../../shared'
import { captalizeFirstChar } from '../../../utils/stringHelper'
import PropTypes from 'prop-types'
import { translations } from "../../../constants/translations";
import moment from "moment";
import styled from "styled-components";
import { getSuspensionReasons } from '../../../services/suspensionReasons'
import { get } from 'lodash';
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../../hooks/apiStateHandler'
import Switch from 'react-switch'



const ViewUser = styled.div`
    input:focus {
        outline: none;
    }
    .btn-group-vertical {
        align-items: center;
        flex-direction: row;
        button {
            margin-right: 10px;
        }
    }
`

const UserDetailEditor = (props) => {
    const { user, parentUser, blockingUser, blockUserHandler, updateInfo, updateUserHandler, updatingUser, subscriptions,deletingPage,deletePageHandler } = props
    const [suspensionReasons, setSuspensionReasons] = useState([]);
    const [selectedReason, setSelectedReason] = useState('');
    const [suspensionReasonsState, suspensionReasonsDispatch] = useReducer(ApiStateReducer, initialState)

    useEffect(() => {
        getSuspensionReasonsHandler()
    }, []);

    const getSuspensionReasonsHandler = async (params) => {
        try {
            suspensionReasonsDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getSuspensionReasons(params)
            if (res.success) {
                suspensionReasonsDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.reasons', []), totalRecords: get(res, 'data.total', 0) })
                setSuspensionReasons(get(res, 'data.reasons', []))
            } else {
                suspensionReasonsDispatch({ type: ApiStateEvents.ERROR, data: [] })
            }
        } catch (error) {
            suspensionReasonsDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    const handleBlockUser = () => {
        blockUserHandler(user._id, !user.isBlocked, selectedReason);
    };
    return <ViewUser><div className="tab-pane fade show active">
        <div className="table-responsive profile-table">
            <table className="table table-responsive">
                <tbody>
                    
                    <tr>
                        <td width="120"><strong>{!parentUser ? translations.Profile.FirstName : translations.Profile.InstitutionName}:</strong></td>
                        <td><input maxLength={50} onChange={(e) => updateInfo('firstName', e.target.value)} type="text" value={user.firstName} /></td>
                        {!parentUser && <><td width="120"><strong>{translations.Profile.LastName}:</strong></td>
                            <td><input maxLength={50} disabled={parentUser} onChange={(e) => updateInfo('lastName', e.target.value)} type="text" value={user.lastName} /></td> </>}
                    </tr>
                    <tr>
                        <td width="120"><strong>{translations.Profile.Email}:</strong></td>
                        <td>{user?.email?.value || 'N/A'}</td>
                        <td width="120"><strong>{translations.Profile.Role}:</strong></td>
                        <td>{captalizeFirstChar(user?.userRoleId?.roleName)}</td>
                    </tr>
                    <tr>
                        <td width="120"><strong>{translations.Profile.DOB}:</strong></td>
                        <td>{user?.dob?.value ? moment(user.dob.value).format("DD MMM, YYYY") : 'N/A'}</td>

                        <td width="120"><strong>{translations.Profile.City}:</strong></td>
                        <td>{captalizeFirstChar(user?.city?.value || 'N/A')}</td>

                    </tr>
                    <tr>
                        <td width="120"><strong>Link:</strong></td>
                        <td><a target="_blank" href={'https://artmo.com/user/' + user.username}>{'https://artmo.com/user/' + user.username}</a></td>
                        <td width="120"><strong>Core Team Member:</strong></td>
                        <td><input type='checkbox' checked={user.coreTeam} name="coreTeam" onChange={(e) => { updateInfo('coreTeam', e.target.checked)}}/></td>
                    </tr>
                    <tr>
                        <td width="120"><strong>Link:</strong></td>
                        <td><a target="_blank" href={'https://artmo.com/user/' + user.username}>{'https://artmo.com/user/' + user.username}</a></td>
                        <td width="120"><strong>Verified Real (not AI):</strong></td>
                        <td><input type='checkbox' checked={user.isVerifiedReal} name="isVerifiedReal" onChange={(e) => { updateInfo('isVerifiedReal', e.target.checked)}}/></td>
                    </tr>
                    <tr>
                    {parentUser && parentUser.email && <Fragment>
                        <td>{translations.Profile.ParentUserEmail}:</td>
                        <td>{parentUser.email?.value}</td>
                        </Fragment>}
                        {user.userRoleId&&user.userRoleId.roleName === 'gallery'&&
                        <Fragment>
                        {/* <td width="70"><strong>Accept:</strong></td>
                        <td><input type='checkbox'checked={user.status === 2} name="status" onChange={(e) => { updateInfo('status', 2)}}/></td>
                        <td width="70"><strong>Reject:</strong></td>
                        <td><input type='checkbox' checked={user.status === 1} name="status" onChange={(e) => { updateInfo('status', 1)}}/></td> */}
                        <td width="270px" style={{display:'flex'}}>
                         <p style={{marginRight:'5px',marginTop:'5px'}}><strong>Not verified</strong></p>
                        <Switch
                        checked={user.status===2}
                        onChange={() => user.status===2? updateInfo('status', 1): updateInfo('status', 2)}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={58}
                        className="react-switch"
                        id="material-switch"
                        />
                        <p style={{marginLeft:'5px',marginTop:'5px'}}><strong>Verified</strong></p>

                        {/* <td width="70"><strong>Accept:</strong></td> */}
                        </td>
                        </Fragment>}
                    </tr>
                </tbody>
            </table>
            <div>
            </div>
        </div>
        <div>
            Subscription settings:
            <table className="table table-responsive">
                <tbody>
                    <tr>
                        <td width="120"><strong>Current subscription: {user.subscription && user.subscription[0] && user.subscription[0].subscription  && user.subscription[0].subscription.title }</strong></td>
                        <td width="120">
                            <select onChange={(e) => updateInfo('sub', e.target.value)}>
                                <option value="">Select</option>
                                {subscriptions.map((subscription) => (
                                    <option key={subscription._id} value={subscription._id}>
                                        {`${subscription.role} ${subscription.title}`}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className='btn-group-vertical'>
            <select onChange={(e) => setSelectedReason(e.target.value)}>
                <option value="">Select Suspension Reason</option>
                {suspensionReasons.map((reason) => (
                    <option key={reason._id} value={reason._id}>
                        {reason.title}
                    </option>
                ))}
            </select>
            <Button loading={blockingUser} loadingText={!user.isBlocked ? 'Suspending' : 'Activating'} clicked={handleBlockUser} className='btn btn-primary'>{!user.isBlocked ? 'Suspend' : 'Activate'}</Button>
            <Button loading={updatingUser} loadingText={'Updating'} clicked={() => updateUserHandler()} className='btn btn-primary'>Update</Button>
            {user.userRoleId && (user.userRoleId.roleName === 'university' || user.userRoleId.roleName === 'gallery' || user.userRoleId.roleName === 'museum' || user.userRoleId.roleName === 'company') &&<Button loading={deletingPage} loadingText={'Deleting'} clicked={() => deletePageHandler(user._id)} className='btn btn-primary'>Delete Page</Button>}
            {/* <Button clicked={() => sendMessageHandler()} >Send Message</Button> */}
        </div>
    </div>
    </ViewUser>
}

UserDetailEditor.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    parentUser: PropTypes.object,
    updateInfo: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    updatingUser: PropTypes.bool.isRequired,
}

UserDetailEditor.defaultProps = {
    user: {},
    loading: false,
    updateInfo: () => null,
    updateUserHandler: () => null,
    updatingUser: false,
    
}

export default UserDetailEditor

